<template>
  <div class="weekAmount">
    <div class="title">
      <p>{{ dataList.title }}</p>
    </div>
    <div class="box">
      <div
        v-for="(item, index) in dataList.data"
        :key="index"
        class="item"
        :class="'row' + (index + 1)"
      >
        <p
          v-for="(el, ids) in item.rank_list"
          :key="'rank_list' + ids"
          :class="item.rank_list.length == 1 ? 'mt-18' : ''"
        >
          <span>{{ el.name }}</span>
          <span>{{ el.count }}</span>
          <span class="min">{{ el.org_name }}</span>
        </p>
      </div>
    </div>
    <div
      class="fill"
      v-if="height / s > 47.64286"
      :style="{ height: height / s - 47.64286 + 'rem' }"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;

    const dataMap = reactive({
      dataList: [],
      height: document.body.clientHeight,
      width: document.body.clientWidth,
      s: parseFloat(
        getComputedStyle(window.document.documentElement)["fontSize"]
      ),
    });

    const activityDataList = () => {
      store
        .dispatch("actActivityDataList", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          option: route.query.option as string,
        })
        .then((res) => {
          if (res.status == 200) {
            if (res.data_list) {
              dataMap.dataList = res.data_list;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    activityDataList();
    return {
      ...toRefs(dataMap),
    };
  },
});
</script>

<style lang="less" scoped>
.weekAmount {
  width: 375px;
  height: 667px;
  background-image: url("/static/images/weekAmount.jpg");
  background-size: 375px 667px;
  background-repeat: no-repeat;
  position: relative;
  .title {
    display: inline-block;
    width: 100vw;
    position: absolute;
    top: 159px;
    p {
      text-align: center;
      font-size: 16px;
      font-weight: 700;
    }
  }
  .box {
    .item {
      p {
        font-size: 12px;
        font-weight: 700;
        span + span {
          margin-left: 5px;
        }
        .min {
          font-size: 9px !important;
        }
      }
    }
    .row1 {
      position: absolute;
      top: 365px;
      left: 145px;
    }
    .row2 {
      position: absolute;
      top: 425px;
      left: 145px;
    }
    .row3 {
      position: absolute;
      top: 487px;
      left: 145px;
    }
    .mt-18 {
      margin-top: 9px;
    }
  }
  .fill {
    font-size: 0;
    position: absolute;
    top: 667px;
    left: 0;
    background-image: url("/static/images/ranking-bg.jpg");
    background-repeat: repeat-y;
    width: 100%;
    background-size: 100% 100%;
  }
}
</style>